import { RavRecord, slotTypes } from '#record/model'
import { useRecordPlugins } from '#record/plugin'
import { useForm } from 'apprise-frontend-core/utils/form'
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant'
import { useRoutableDrawer } from 'apprise-ui/drawer/drawer'
import { Fields, useValidation } from 'apprise-ui/field/validation'
import { useEffect } from 'react'
import { delistingParam } from './constants'
import { DetailProps } from './detail'
import { useVesselModel } from './model'


export const useDetailData = (props: DetailProps) => {

    const model = useVesselModel()
    const validation = useValidation()
    const plugins = useRecordPlugins()

    const logged = useTenancyOracle()
    
    const delistingKit = useRoutableDrawer(delistingParam)

    const { vessel, age = 0, historySlot } = props

    // most recent record in history
    const current = vessel.history[0]

    const isNew = !current.uvi

    // prepared an editable version of the vessel: 
    // if the vessel is not already new, starts it off the previous record (new id, timestamp, prefilled blank slots).
    const form = useForm<RavRecord>(isNew ? current : model.nextRecordFrom(current))

    const { edited, dirty } = form

    // if edits have started, append them to history.
    const currentVessel = dirty ? { ...vessel, history: [edited, ...vessel.history] } : vessel

    const history = currentVessel.history

    // selected record in history.
    const record = history[age]  

    // keeps form in sync with current.
    useEffect(() => {

        if (current.id !== edited.id) {
            form.reset.to(model.nextRecordFrom(current)).quietly()
        }

        // eslint-disable-next-line
    }, [current])


    const groupedfields = logged.isGuest() ? {} : slotTypes.map(type => (

        { [type]: plugins.lookup(type).formFields({ current, isNew, ...form}) }

    )).reduce((acc, fields) => ({ ...acc, ...fields }), {} as Fields)

    const fields = logged.isGuest() ? {} : slotTypes.map(plugins.lookup)
        .map(plugin => plugin.formFields({ ...form, current, isNew }))
        .reduce((acc, fields) => ({ ...acc, ...fields }), {} as Fields)


    const report = validation.reportOf(fields)

    const delisted = !dirty && model.delisted(record)

    return {

        props: { ...props, vessel: currentVessel, history }

        , form, isNew,

        fields, groupedfields, report,

        vessel: currentVessel, record, current, age,

        history, historySlot,

        delisted,

        delistingKit

    }


}



