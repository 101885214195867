import { useT } from 'apprise-frontend-core/intl/language'
import { useBusyGuardIndicator } from 'apprise-frontend-core/utils/busyguard'
import { utils } from 'apprise-frontend-core/utils/common'
import { useForm } from 'apprise-frontend-core/utils/form'
import { Button, ButtonRow } from 'apprise-ui/button/button'
import { Form } from 'apprise-ui/form/form'
import { GroupBox } from 'apprise-ui/groupbox/groupbox'
import { RouteGuard } from 'apprise-ui/link/routeguard'
import { useUIPreferences } from 'apprise-ui/preferences'
import { SwitchBox } from 'apprise-ui/switchbox/switchbox'
import { Topbar } from 'apprise-ui/topbar/topbar'
import { Explainer } from 'apprise-ui/utils/explainer'
import { RevertIcon } from 'apprise-ui/utils/icons'
import React from 'react'
import { AiOutlineCheck } from 'react-icons/ai'
import { FaLayerGroup } from 'react-icons/fa'
import { FiLayers } from 'react-icons/fi'
import { MdOutlineRestartAlt } from 'react-icons/md'
import { TablePreferences, useTableApi } from './api'
import { ColumnProps, TableProps } from './table'


export const TableColumnPanel = (props: TableProps<any>) => {

    const t = useT()

    const api = useTableApi(props)

    const { SavePreferencesButton, get: getPreferences, set: setPreferences } = useUIPreferences<TablePreferences>()

    // all columns provided to the table as children
    const allCols = api.allColumns()
    const allNames = allCols.map(c => c.name)

    const defaultNames = api.defaultLayout().map(c => c.name)

    // initial layout: list and map of selected cols.
    const initialOf = (selected: (string | undefined)[]) => {

        const map = utils().index(allNames).mappingBy(n => n, n => selected.includes(n))

        const others = allNames.filter(k => !map[k!])

        return { names: [...selected, ...others] as string[], map }
    }

    // eslint-disable-next-line
    const initialLayout = React.useMemo(() => initialOf(api.currentLayout().map(c => c.name)), [])

    const { edited, set, dirty, reset } = useForm(initialLayout)

    const allSelected = Object.values(edited.map).filter(e => e).length === allNames.length

    const activeNames = edited.names.filter(k => !!edited.map[k])

    const apply = () => {

        api.setCurrentLayout(activeNames)

        reset.to(edited).quietly()

    }


    const resetToDefault = async () => {

        // clear preferences.
        if (getPreferences().tables)
            await setPreferences(p => p.tables[props.name!] = undefined!)

        // clear session.
        api.setCurrentLayout(undefined!)

        // reset form to default.
        reset.to(initialOf(defaultNames)).quietly()

    }

    const toggleAll = (state: boolean) => set.using(m => Object.keys(m.map).forEach(name => m.map[name] = state))

    const revert = reset.toInitial.quietly
    const reorder = set.with((m, names: string[]) => m.names = names)
    const select = (name: string) => set.with((m, v) => m.map[name] = !!v)

    const preferredColumns = getPreferences().tables?.[props.name!]?.layout || []

    // save btn lights up if the current selection is not already saved, 
    // or if there's nothign saved and the selection is not the default.
    const enableSave = !utils().deepequals(preferredColumns.length === 0 ? defaultNames : preferredColumns, activeNames)

    // revert to default light up if preferences or current selection aren't the default ones.
    const enableRevertToDefault = !utils().deepequals(preferredColumns.length === 0 ? activeNames : preferredColumns, defaultNames)

    //buttons

    const applyBtn = <Button noReadonly type='primary' icon={<AiOutlineCheck />} enabled={dirty} onClick={apply}>
        {t("ui.table.column_apply")}
    </Button>

    const revertBtn = <Button noReadonly icon={<RevertIcon />} enabled={dirty} onClick={revert}>
        {t("ui.table.column_discard")}
    </Button>

    const resetToDefaultBtn = <Button tipDelay={1} noReadonly disabled={dirty} enabled={enableRevertToDefault} tip={t('ui.table.column_default_tip')} type='link' iconPlacement='left' className="default-btn" icon={<MdOutlineRestartAlt />} onClick={resetToDefault}>
        {t("ui.table.column_default")}
    </Button>


    const selectAllBtn = <Button tipDelay={1} noReadonly disabled={allSelected} tip={t('ui.table.select_all_cols_tip')} type='link' iconPlacement='right' icon={<FaLayerGroup />} className="default-btn" onClick={() => toggleAll(true)}>
        {t("ui.table.select_all_cols")}
    </Button>

    const deselectAllBtn = <Button tipDelay={1} noReadonly disabled={!activeNames.length} tip={t('ui.table.deselect_all_cols_tip')} type='link' iconPlacement='left' icon={<FiLayers />} className="default-btn" onClick={() => toggleAll(false)}>
        {t("ui.table.deselect_all_cols")}
    </Button>

    const Indicator = useBusyGuardIndicator()

    return <>

        <RouteGuard when={dirty} ignoreQueryParams={false} />

        <Topbar>
            <ButtonRow>

                {applyBtn}

                {revertBtn}

            </ButtonRow>
        </Topbar>

        <Explainer>{t('ui.table.column_explainer')}</Explainer>

        <Explainer>{t('ui.table.column_explainer2')}</Explainer>

        <div style={{ display: 'flex', alignItems:'center', justifyContent: 'space-evenly' }}>

            <div>
                {selectAllBtn}
            </div>

            <div>
                {deselectAllBtn}
            </div>
        </div>

        <Indicator>
            <Form style={{ marginTop: 20 }}>

                <GroupBox disableIf={name => !!allCols.find(e => e.name === name)?.pinned} noReadonly noAdd noRemove onChange={reorder}

                    render={name => {

                        const column = allCols.find(e => e.name === name)

                        return <ColumnCard column={column} value={edited.map[name]} onSelect={select(name)} />
                    }} >

                    {edited.names}

                </GroupBox>

            </Form>
        </Indicator>

        <div style={{marginTop:20, display:'flex', justifyItems: 'center', justifyContent:'space-between'}}>

            {resetToDefaultBtn}

            <SavePreferencesButton noReadonly disabled={dirty} enabled={enableSave} updater={api.saveCurrentLayout} />
            
        </div>

    </>
}


export type ColumnCardProps = {

    column?: ColumnProps
    value: boolean
    onSelect: (_: boolean | undefined) => void
}

export const ColumnCard = (props: ColumnCardProps) => {

    const t = useT()

    const { column, value, onSelect } = props

    return <GroupBox.Card className='column-card' key={column?.name} >
        <div>{column?.title || column?.name}</div>
        <SwitchBox style={{ marginLeft: 'auto' }} tipPlacement='rightTop' tip={t('ui.table.column_switch_tip')} tipDelay={1} disabled={column?.pinned} onChange={onSelect}>
            {value}
        </SwitchBox>
    </GroupBox.Card>
}