import { RavRecord } from '#record/model'
import { Vid } from '#vid/model'
import { useCall } from 'apprise-frontend-core/client/call'
import { useT } from 'apprise-frontend-core/intl/language'
import { useAsyncTask } from 'apprise-ui/utils/asynctask'
import { useVesselCache } from './cache'
import { Vessel } from './model'

export const vesselApi = `/vessel`

export const useVesselCalls = () => {

    const t = useT()

    const call = useCall()
    const task = useAsyncTask()

    const cache = useVesselCache()

    const self = {


        fetchVessel: task.make(async (uvi: Vid) => {

            const vessel = await call.at(`${vesselApi}/${encodeURIComponent(uvi)}`).get<Vessel>()

            // sanity check (can cause loops too in effect mgmt of clients.)
            if (vessel.uvi !== uvi)
                throw new Error(t(`vessel.baduvi_error`, { uvi: vessel.uvi }))

            cache.set(vessel)

            return vessel

        })
            .with($ => $.minimumDuration(200)
                .log(uvi => `fetching vessel ${uvi}...`)
                .show(t('vessel.loading')))
            .done()

        ,

        addRecord: task.make(async (record: RavRecord): Promise<RavRecord> => {

            const added = await call.at(`${vesselApi}/${encodeURIComponent(record.uvi)}`).post<RavRecord>(record)

            cache.udpateWith(added);

            return added

        }).with($ => $.wait(200)
            .log(record => `adding record for vessel ${record.uvi}...`)
        )
            .done()

        ,



        remove: async (record: RavRecord): Promise<void> => {

            console.log(`removing record for vessel ${record.uvi}...`)

            await call.at(`${vesselApi}/${encodeURIComponent(record.uvi)}/${record.id}`).delete()

            self.fetchVessel(record.uvi)

        }
    }


    return self

}

