
import { useComponentBridge } from 'apprise-frontend-core/utils/bridge';
import { noop } from 'apprise-frontend-core/utils/function';
import { Wide } from 'apprise-ui/component/model';
import { useChangeHelper } from 'apprise-ui/field/changehelper';
import { Field, useFieldProps } from 'apprise-ui/field/field';
import { ChangeTracked, Fielded } from 'apprise-ui/field/model';
import { useReadonlyHelper } from 'apprise-ui/field/readonlyhelper';
import { useResetHelper } from 'apprise-ui/field/resethelper';
import { RemoveItemIcon } from 'apprise-ui/utils/icons';
import React, { lazy, Suspense } from 'react';


export type DateBoxProps<T extends number | string> = Fielded<T> & Wide & ChangeTracked<T> & Partial<{

    children: T | undefined

    noClear: boolean

    noInput?: boolean

}>


const DatePicker = lazy(() => import("./datefnsplugin"))

export const defaultFormats = ['dd/MM/yyyy', 'd/MM/yyyy', 'dd/M/yyyy', 'dd/MM/yy', 'd/MM/yy', 'dd/M/yy']

export const DateBox = <T extends number | string>(clientprops: DateBoxProps<T>) => {

    const { Indicator } = useComponentBridge()
    // changes to force a remount on reset.
    const [resetKey, resetSetKey] = React.useState(0)

    const props = useFieldProps(clientprops)

    const { pastMode, pastValue } = useChangeHelper(props)

    useReadonlyHelper(props)

    useResetHelper(props, {

        onReset: () => {

            onChange?.(undefined)       // won't reassert the default

            resetSetKey(s => ++s)         // forces a remount to do that.
        }
    })

    const { readonly, onChange: clientOnChange, defaultValue, children, placeholder, disabled, focusDecorations = [], noClear, noInput, ...rest } = props

    const numeric = typeof children === 'number'

    const latestValue = pastMode ? pastValue : (children ?? defaultValue)

    const onChange = readonly ? noop : clientOnChange

    props.debug && console.log("datebox", { resetKey, defaultValue, children, onChange })

    const readonlyProps = readonly ? {

        open: false,
        inputReadOnly: true,
        clearIcon: false
    }

        : {

            clearIcon: <RemoveItemIcon />,
            inputReadOnly: false,

        }


    return <Field name='datebox' {...rest} focusDecorations={focusDecorations}>

        <Suspense fallback={<Indicator waiting />}>
            <DatePicker style={{ width: 280 }} key={resetKey}

                allowClear={!noClear}
                placeholder={placeholder}
                value={latestValue ? new Date(latestValue) : undefined}
                format={defaultFormats}
                onChange={date => onChange?.((numeric ? date?.getTime() : date?.toISOString()) as T)}

                disabled={disabled}

                {...readonlyProps}

                inputReadOnly={noInput}
            />
        </Suspense>

    </Field>

}