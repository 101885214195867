import { authorizationType } from '#authorization/constants';
import { ClaimIcon, delistColor, DelistingIcon, delistingType } from '#delisting/constants';
import { fishingKindTag } from '#details/constants';
import { isCarrierVessel } from '#details/model';
import { RecordDateIcon, RecordExpiredIcon, RecordIdIcon, VesselIcon } from '#record/constants';
import { RecordLabel } from '#record/label';
import { RavRecord, SlotType, slotTypes } from '#record/model';
import { useRecordPlugins } from '#record/plugin';
import { draftColor } from '#submission/constants';
import { ClaimDecoration } from '#submission/patchlabel';
import { useSubmissionRouting } from '#submission/routing';
import { RecordIssue, RecordValidationContext } from '#submission/validator';
import { TrxTypeIcon } from '#trxauth/constants';
import { useDateHelper } from '#utils/datehelper';
import { useVesselModel, Vessel } from '#vessel/model';
import { useVID, Vid } from '#vid/model';
import { useMode } from 'apprise-frontend-core/config/api';
import { useT } from 'apprise-frontend-core/intl/language';
import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard';
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant';
import { TenantLabel } from 'apprise-frontend-iam/tenant/label';
import { TagLabel } from 'apprise-frontend-tags/tag/label';
import { Button } from 'apprise-ui/button/button';
import { classname } from 'apprise-ui/component/model';
import { useValidation } from 'apprise-ui/field/validation';
import { Label, LabelRow } from 'apprise-ui/label/label';
import { LifecycleSummary } from 'apprise-ui/lifeycle/lifecyclesummary';
import { NoSuchRoute } from 'apprise-ui/link/nosuchroute';
import { RouteGuard } from 'apprise-ui/link/routeguard';
import { Page } from 'apprise-ui/page/page';
import { SidebarContent, SidebarProperty } from 'apprise-ui/page/sidebar';
import { Column, Table } from 'apprise-ui/table/table';
import { Tab, useTabs } from 'apprise-ui/tabs/tab';
import { Titlebar } from 'apprise-ui/titlebar/titlebar';
import { Tip } from 'apprise-ui/tooltip/tip';
import { Topbar } from 'apprise-ui/topbar/topbar';
import { DevOnlyIcon, EditIcon, LeftIcon, OpenIcon, RevertIcon, RightIcon, SaveIcon } from 'apprise-ui/utils/icons';
import React, { Fragment, useContext, useRef } from 'react';
import { delistingReasonDeflagged } from '../delisting/constants';
import { useVesselCache } from './cache';
import { useVesselCalls } from './calls';
import { delistingParam, editModeParam } from './constants';
import { DetailContext } from './context';
import { DelistingPanel } from './delistingpanel';
import "./detail.scss";
import { useEffects } from './effects';
import { useDetailData } from './formdata';
import { useVesselOracle } from './oracle';
import { Overview, TranshipmentOverview } from './overview';
import { useVesselRouting } from './routing';


export type VesselDetailProps = {

    uvi: Vid
    age?: number
    historySlot?: SlotType | undefined
}

export const NewVessel = () => {

    const model = useVesselModel()

    // mints a new vessel with a first empty record in it.
    // why memoise it? minting new vessels at each future render is wasteful and
    // makes it look like we've navigated to a different vessel each time.
    const newVessel = useRef(model.newVessel())

    return <DetailProvider vessel={newVessel.current} />
}


// renders a vessel, loading it if it hasn't been before, whilst keeping the UI as stable as possible.
export const VesselDetail = (props: VesselDetailProps) => {


    const cache = useVesselCache()
    const calls = useVesselCalls()

    const { uvi, age, historySlot } = props

    const cached = cache.get(uvi)

    //  why store vessels here if we already have a cache ?
    //  because we want a stable UI, so we keep cached vessels around until we have loaded and cached new ones. 
    //  we also want different loading behaviours: if we don't have a vessel to render we use the global spinner.
    //  if we have a vessel to render we use a local spinner. for the latter case we must load from children,
    //  below a a <LazyBusyGuard />. We dedicate the <Reloader/> to this task.
    const [rendered, renderedSet] = React.useState<Vessel>(cached)

    // renders only if we have a vessel in the cache, otheriwse loads it.
    const { content } = useRenderGuard({

        when: !!rendered,

        // why pass vessel identifiers down? so children know when they change and new vessels have to be loaded.
        render: () => rendered?.history.length ?

            <ReLoader {...props} uvi={uvi}>
                <DetailProvider historySlot={historySlot} vessel={rendered} age={age} />
            </ReLoader>

            :

            <NoSuchRoute />

        ,

        orRun: () => calls.fetchVessel(uvi)



    })

    // when the cache is ready, replace current vessel.
    React.useEffect(() => {

        if (cached && cached !== rendered)
            renderedSet(cached)

        // eslint-disable-next-line
    }, [cached])

    return content


}

// loads a new vessel, if it isn't already in the cache.
const ReLoader = (props: React.PropsWithChildren<VesselDetailProps>) => {

    const { uvi, children } = props

    const cache = useVesselCache()
    const calls = useVesselCalls()


    React.useEffect(() => {

        cache.get(uvi) || calls.fetchVessel(uvi)

        // eslint-disable-next-line
    }, [uvi])

    return <React.Fragment>
        {children}
    </React.Fragment>

}


export type DetailProps = {

    vessel: Vessel
    age?: number
    historySlot?: SlotType | undefined
    editSlot?: SlotType | undefined

}



const DetailProvider = (props: DetailProps) => {

    const data = useDetailData(props)

    return <DetailContext.Provider value={data}>
        
        <DetailPage />
    </DetailContext.Provider>
}



export const DetailPage = () => {

    const t = useT()

    const oracle = useVesselOracle()

    const logged = useTenancyOracle()

    const { isNew, form, age, record, delisted, delistingKit } = useContext(DetailContext)

    const { dirty } = form

    const btns = useButtons()

    const loggedSidebar = useSidebar()

    const guestSidebar = useGuestSidebar()

    const sidebar = logged.isGuest() ? guestSidebar : loggedSidebar

    // must be based on original record, no edited (delisting data is removed as edited is prepared for submission)
    const readonly = age > 0 || !oracle.canEdit(record)

    const title = isNew ? t('rec.newtitle') : delisted ? <span className="title-delisted">{record.details.name}</span> : record.details.name

    const dirtyLabel = dirty && age === 0 && <Label fill={draftColor} title={t('rec.state_draft')} />
    const delistedLabel = delisted && <RecordLabel linkTo={delistingKit.routeAt()} displayMode='state' record={record} />

    const vesselTab = useVesselTab()

    const { tabs: carrierTabs, selectedTabContent: carrierTab } = useTabs([

        <Tab defaultTab icon={<VesselIcon />} id='profile' label={t("vessel.carrier_tab")}>
            {vesselTab}
        </Tab>,

        <Tab enabled={!!record.trxauthz?.authzs} icon={<TrxTypeIcon />} id='trx' label={t("vessel.trx_tab")}>
            <TranshipmentOverview />
        </Tab>

    ])

    return <Page readonly={readonly} className={classname('vessel-detail', delisted && 'vessel-delisted')} defaultOpen>

        <Titlebar title={title}>
            <LabelRow mode='tag'>
                {age > 0 && <Label icon={<RecordIdIcon />} fill='crimson' title={t('vessel.historical')} /> }  
                <TagLabel noIcon bare fill={record.details.vesselKind === fishingKindTag ? 'deepskyblue' : 'darkorange'} tag={record.details.vesselKind ?? fishingKindTag} />

                {record.delisting?.reason !== delistingReasonDeflagged &&

                    <TenantLabel bare fill='dodgerblue' tenant={record.tenant} />
                }
                {dirtyLabel}
                {!isNew && <RecordLabel bare fill='lightseagreen' displayMode='date' dateMode='short' record={record} />}
                {delistedLabel}
                {!isNew && <RecordLabel displayMode='expiry' dateMode='short' record={record} />}
            </LabelRow>
        </Titlebar>

        {logged.isGuest() ||

            <Topbar tabs={isCarrierVessel(record) ? carrierTabs : undefined}>
                {btns.primary}
            </Topbar>
        }

        {sidebar}

        {isCarrierVessel(record) ? carrierTab : vesselTab}

        <DelistingPanel />

        <RouteGuard when={dirty} ignoreQueryParams={['age', 'history', ...slotTypes.map(type => `${editModeParam}-${type}`), delistingParam]} />

    </Page>

}

const useVesselTab = () => {

    const { form, fields } = useContext(DetailContext)

    const { dirty } = form

    const formErrorsAsValidationErrors = dirty ? Object.values(fields)
        .filter(info => info.status === 'error' || info.status === 'warning')
        .map(({ msg, status, location }) => ({ type: status, message: msg, location } as RecordIssue)) : []

    return <RecordValidationContext.Provider value={formErrorsAsValidationErrors}>

        <Overview />

    </RecordValidationContext.Provider>

}


const useSidebar = () => {

    const t = useT()
    const vid = useVID()
    const logged = useTenancyOracle();

    const plugins = useRecordPlugins()

    const { isNew, report, form, record, delistingKit } = useContext(DetailContext)

    const btns = useButtons()
    const sidelist = useSidelist()

    const { dirty } = form

    const blockingErrors = report.errors()

    return <SidebarContent>

        {btns.back && <>{btns.back} <br /></>}
        {(logged.hasNoTenant() || logged.isManagerOf(record.tenant) || logged.tenant() === record.tenant) && <>{btns.origin} <br /></>}

        {btns.submit}
        {btns.revert}
        {btns.remove}

        <br />

        {btns.delist}
        {btns.claim}

        <br />

        {btns.newer}
        {React.cloneElement(btns.older, { iconPlacement: 'right' })}

        <br />

        {isNew || <Fragment>

            <SidebarProperty name={vid.schemeOf(record.uvi)?.name}>
                {vid.valueOf(record.uvi)}
            </SidebarProperty>

            <SidebarProperty name={t("common.id")}>
                {record.id}
            </SidebarProperty>

            <br />

            <SidebarProperty name={t('vessel.state_sideprop')}>
                {t(`rec.state_${record.lifecycle.state}`)}
            </SidebarProperty>

            <SidebarProperty name={t('vessel.updates_sideprop')}>

                <div className='patched-slots'>
                    {record.patchedSlots.map((p, i) => {

                        const { singular } = plugins.lookup(p)

                        if (p === delistingType)
                            return <Button className="patched-slot-label" key={i} icon={<OpenIcon className='delisted-icon' />} type='ghost' onClick={delistingKit.toggle}>{singular}</Button>

                        return <span key={i} className="patched-slot-label">{singular}</span>
                    })}
                </div>

            </SidebarProperty>

            <br />

        </Fragment>}

        {(isNew || dirty) && (blockingErrors > 0 ?

            <SidebarProperty type='error'>{t('common.total_error_count', { count: blockingErrors })}</SidebarProperty>
            :
            <SidebarProperty>{t('common.no_errors')}</SidebarProperty>)

        }

        <br />

        <LifecycleSummary lifecycle={record.lifecycle} />

        <br />
        <br />

        {sidelist}


    </SidebarContent>
}



const useGuestSidebar = () => {

    const sidelist = useSidelist()

    return <SidebarContent>

        {sidelist}

    </SidebarContent>
}

const useButtons = () => {

    const t = useT()
    const mode = useMode()

    const oracle = { ...useTenancyOracle(), ...useVesselOracle() }
    const validation = useValidation()
    const routing = useVesselRouting()

    const submissions = useSubmissionRouting()

    const effects = useEffects()

    const { isNew, age, historySlot, history, fields, delisted, form, record, delistingKit } = useContext(DetailContext)

    const { save, remove } = useEffects()

    const { edited, dirty, reset } = form

    const discardChanges = reset.toInitial.confirm

    const report = validation.reportOf(fields)

    const blockingErrors = report.errors()

    const backRoute = routing.backRoute()

    const backBtn = backRoute &&
    
        <Button type={dirty ? 'normal' : 'primary'} key='backlink' icon={<OpenIcon />} noReadonly linkTo={backRoute}>{t('vessel.submission_backlink')}</Button>

    const originBtn = <Button enabled={!!record.origin} type={dirty ? 'normal' : 'primary'} key='originlink' icon={<OpenIcon />} noReadonly linkTo={submissions.detailRoute(record.origin)}>{t('vessel.submission_originlink')}</Button>

    const olderBtn = <Button key='older' noReadonly icon={<LeftIcon />} iconPlacement='left' linkTo={routing.innerDetailRoute(age + 1, historySlot)} enabled={(age + 1) < history.length}>
        {t("vessel.prev_btn")}
    </Button>

    const newerBtn = <Button key='newer' noReadonly icon={<RightIcon />} linkTo={routing.innerDetailRoute(age - 1, historySlot)} enabled={age > 0}>
        {t("vessel.next_btn")}
    </Button>

    const submitBtn = oracle.canEdit(edited) && <Button enabled={dirty} disabled={blockingErrors > 0} dot={dirty && blockingErrors > 0} key='submit' icon={<SaveIcon />} onClick={() => save()}>
        {t("vessel.save_btn")}
    </Button>

    const revertBtn = oracle.canEdit(edited) && <Button enabled={dirty} key='revert' icon={<RevertIcon />} onClick={discardChanges}>
        {t("vessel.revert_btn")}
    </Button>

    const delistBtn = oracle.canEdit(edited) && <Button enabled={age === 0} disabled={isNew || dirty || delisted} key='delist' icon={<DelistingIcon color={delistColor} />} onClick={delistingKit.toggle}>
        {t("vessel.delist_btn")}
    </Button>


    const claimBtn = oracle.canClaim(record) && <Button noReadonly enabled={age === 0} disabled={dirty} key='claim' icon={<ClaimIcon />} onClick={effects.claim}>
        {t("vessel.claim_btn")}
    </Button>


    const removeBtn = mode.development && <Button key='remove' disabled={isNew || dirty} icon={<DevOnlyIcon />} noReadonly onClick={() => remove(record)}>Remove Record</Button>

    const primaryBtn = dirty ? oracle.canEdit(edited) ? submitBtn : null : null

    return { primary: primaryBtn, submit: submitBtn, revert: revertBtn, delist: delistBtn, claim: claimBtn, older: olderBtn, newer: newerBtn, back: backBtn, origin: originBtn, remove: removeBtn }
}


const useSidelist = () => {

    const t = useT()


    const { isNew, vessel, record, history, historySlot } = useContext(DetailContext)

    return isNew ||

        <SidebarContent.Affix>

            <SidebarContent.Title>{t('vessel.history_title')}</SidebarContent.Title>
            <Table.Sider name={`vessel-${vessel.uvi}`} noFilter key={`${record.id}-${historySlot}`} data={history} rowId={r => r.id ?? r.timestamp}>

                <Column<RavRecord>
                    defaultLayout name='date' width={150}
                    title={<Label icon={<RecordDateIcon />} title={t('search.date_col')} />}
                    render={(r, _, age) => {
                        return <SideLabel current={record} age={age} record={r} previous={history[age + 1]} />
                    }} />

            </Table.Sider>
        </SidebarContent.Affix>


}

const SideLabel = (props: { current: RavRecord, record: RavRecord, previous?: RavRecord, age: number }) => {

    const model = useVesselModel()
    const routing = useVesselRouting()

    const helper = useDateHelper()

    const { current, record, previous, age } = props

    const isNew = !record.lifecycle.created

    const hasExpired = age === 0 && helper.expired(new Date(record[authorizationType].to))
    let decorations = hasExpired ? [<RecordExpiredIcon />] : undefined

    if (previous && record.tenant !== previous.tenant)
        decorations = [...decorations ?? [], <ClaimDecoration tenant={previous.tenant} />]


    const icon = isNew ? <EditIcon /> : model.delisted(record) ? <DelistingIcon color={delistColor} /> : undefined

    const base = <RecordLabel style={{flexShrink:0}} icon={icon} linkTo={routing.innerDetailRoute(age)} highlighted={record.id === current.id} displayMode={'date'} dateMode='short' decorations={decorations} record={record} />

    return (!previous || previous.details.name === record.details.name) ? base : 
    
        <div style={{ display: 'flex' }}>
            {base} &nbsp; 
            <Tip style={{display: 'inline-grid'}} tip={record.details.name}>
                <div style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>({record.details.name})</div>
            </Tip>
        </div>
}