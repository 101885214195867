import React from "react"
import { EventInitialiser } from "./initialiser"
import { EventProvider } from "./provider"

export type EventsProps = React.PropsWithChildren<Partial<{

    mock: boolean

}>>


export const Events = (props: EventsProps) => {


    const { children } = props

    return <EventProvider>
        <EventInitialiser {...props} >
                {children}
        </EventInitialiser>
    </EventProvider>


}






