
import { utils } from "apprise-frontend-core/utils/common"
import fastsort from "fast-sort"
import { useContext } from "react"
import { useTableApi } from "./api"
import { TableContext } from './context'
import { ColumnProps, TableElement, TableProps } from "./table"


export type SortMode = 'asc' | 'desc' | undefined

export const defaultComparator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare

export type SortSpec = {

    key: number | string
    mode: SortMode | undefined

}

export const useSort = <E extends TableElement>(props: TableProps<E>) => {

    const api = useTableApi(props);

    const ctx = useContext(TableContext)

    const specs = ctx.get()[props.name!]?.sort ?? props.initialSort ?? []

    const sort = (records: E[]) => {

        if (specs.length === 0)
            return records

        const cols = api.currentLayout().reduce((acc, val, i) => ({ ...acc, [i]: val, [val.name ?? i]: val }), {} as Record<SortSpec['key'], ColumnProps<E>>)

        const adaptedspecs = specs.filter(spec => spec.mode).map(spec => {

            const col = cols[spec.key]

            if (!col)
                return false // col is gone, perhaps with custom layout.

            const dataAccessor = accessor(col.path ? utils().arrayOf(col.path) : [])

            return { [spec.mode!]: dataAccessor, comparer: typeof col.sort === 'function' ? col.sort : col.sort === 'external' ? () => 0 : defaultComparator }

        })
            .filter(spec => spec)  // filter out invalid specs

        // fastsort changes data in place, we create return a copy to trigger a render.

        const sorted = [...records]

        fastsort(sorted).by(adaptedspecs as any)

        return sorted

    }


    const onSortChange = (key: SortSpec['key'], multi = false) => {

        ctx.set(state => {

            let newspecs

            if (multi) {

                const match = specs.find(spec => spec.key === key)


                newspecs = match ?

                    specs.map((spec, i) => {

                        const mode = flipSortMode(spec.mode)

                        return mode ? match.key === spec.key || i ? { ...spec, mode } : spec : undefined


                    }).filter(spec => !!spec)

                    :

                    [...specs, { key, mode: 'asc' }]


            }

            else

                newspecs = key === specs[0]?.key ? [{ ...specs[0], mode: flipSortMode(specs[0]?.mode) }] : [{ key, mode: 'asc' }]


            state[props.name!] = { ...state[props.name!], sort: newspecs }
        })

    }

    const statemap = specs.reduce((acc, spec) => ({ ...acc, [spec.key]: spec.mode }), {} as Record<SortSpec['key'], SortMode>)


    return { sort, onSortChange, specs: statemap }

}

const accessor = (path: string[]) => (o: TableElement) => {

    if (!o)
        return undefined

    if (path.length === 0)
        return o

    const [first, ...rest] = path

    return accessor(rest)(o[first])
}

const flipSortMode = (mode: SortMode): SortMode => mode === 'asc' ? 'desc' : mode === 'desc' ? undefined : 'asc'