import { authorizationType } from '#authorization/constants'
import { delistColor, DelistingIcon, delistingType } from '#delisting/constants'
import { useAppSettings } from '#settings/settings'
import { useDateHelper } from '#utils/datehelper'
import { DateLabel, DateLabelProps } from '#utils/datelabel'
import { useVesselCache } from '#vessel/cache'
import { useVesselCalls } from '#vessel/calls'
import { useVesselModel } from '#vessel/model'
import { useVesselRouting } from '#vessel/routing'
import { VidLabel } from '#vid/label'
import { useT } from 'apprise-frontend-core/intl/language'
import { useTagUtils } from 'apprise-frontend-tags/tag/utils'
import { Label, LabelProps, UnknownLabel } from 'apprise-ui/label/label'
import { Tip } from 'apprise-ui/tooltip/tip'
import { AllertIcon } from 'apprise-ui/utils/icons'
import { useDateFnsLocale } from 'apprise-ui/utils/localeloader'
import format from 'date-fns/format'
import { GenericRecord, RavRecord } from '../record/model'
import { RecordExpiredIcon, RecordIgnoredIcon, RecordPublishedIcon, RecordRejectedIcon, RecordSubmittedIcon, RecordUploadedIcon, VesselIcon } from './constants'
import { useRecordPluginDescriptors } from './plugin'

export type RecordLabelProps = LabelProps & Partial<{

    record: RavRecord

    age: number,

    iconMode: 'normal' | 'patch'

    displayMode: 'id' | 'date' | 'state' | 'expiry'

    dateMode: DateLabelProps['dateMode']

}>

export const RecordLabel = (props: RecordLabelProps) => {

    const t = useT()
    const routing = useVesselRouting()
    const cache = useVesselCache()
    const calls = useVesselCalls()

    const dateSettingsFormat = useAppSettings().resolveFormat()

    const { record, icon: clientIcon, linkTo, age = 0, iconMode = 'patch', displayMode = 'id', dateMode, ...rest } = props

    const plugins = useRecordPluginDescriptors()

    const locale = useDateFnsLocale()
    const helper = useDateHelper()

    if (!record)
        return <UnknownLabel />

    let icon: JSX.Element | undefined = clientIcon

    const id = record.uvi

    const defaultedlinkTo = linkTo ?? routing.detailRoute(id, age)

    const preload = cache.get(id) ? undefined : () => calls.fetchVessel(id)


    if (!icon && (iconMode === 'patch')) {

        const recordslots = record.patchedSlots.join()
        const Icon = plugins.all().find(t => recordslots === t.patchedTypes.join())?.Icon ?? VesselIcon
        const tip = t('rec.patchlabel_tip', { patched: record.patchedSlots.map(t => plugins.lookup(t).singular).join(", ") })

        icon = <Tip tip={tip}><Icon /></Tip>

    }

    if (displayMode === 'state')

        return <RecordStateLabel record={record}  {...props} />


    if (displayMode === 'date')

        return <DateLabel icon={icon} date={record.timestamp} dateMode={dateMode} linkTo={defaultedlinkTo} {...rest} />

    if (displayMode === 'expiry') {

        const hasExpired = age === 0 && helper.expired(new Date(record[authorizationType].to))
        const computedFormat = dateMode === 'settings' ? dateSettingsFormat : dateMode === 'short' ? 'PP' : 'PPp'
        const expiryDate = record[authorizationType].to ?? record[delistingType]?.date
        const date = expiryDate &&  format(Date.parse(expiryDate), computedFormat, {locale})

        return hasExpired ? <Label fill='orange' mode='tag' tip={t('rec.expired_on', { date })} icon={<RecordExpiredIcon />} title={t('rec.expired')} />

            :

           <Label mode='tag' tip={t('rec.expires_on', { date })} icon={<AllertIcon color='dodgerblue' />} title={t('rec.expires_on', { date: date || `: ${t('vessel.na_label')}` })} />

    }

    return <VidLabel icon={icon} vid={id} linkTo={defaultedlinkTo} linkOn={preload} {...rest} />


}


export type RecordStateProps = LabelProps & Partial<{

    record: GenericRecord

}>

export const RecordStateLabel = (props: RecordStateProps) => {

    const t = useT()

    const { record, ...rest } = props

    const model = useVesselModel()

    if (!record)
        return null;
    
    if (record.lifecycle.state === 'published' && model.delisted(record))
        return <DelistedRecordLabel {...props} />

    const state = record?.lifecycle.state

    const Icon = state === 'uploaded' ? RecordUploadedIcon :
        state === 'ignored' ? RecordIgnoredIcon :
            state === 'rejected' ? RecordRejectedIcon :
                state === 'submitted' ? RecordSubmittedIcon
                    : RecordPublishedIcon


    const iconColor = state === 'uploaded' ? 'seagreen' : state === 'ignored' ? 'lightgrey' : state === 'rejected' ? 'inherit' : 'dodgerblue'

    const title = state ? t(`rec.state_${state}`) : t('rec.no_state')

    return <Label icon={<Icon color={iconColor} />} title={title} {...rest} />

}

const DelistedRecordLabel = (props: RecordStateProps) => {

    const t = useT()

    const { record, fill, ...rest } = props     // capture fill to inhibit overrides.

    const locale = useDateFnsLocale()

    const tags = useTagUtils()

    if (!record)
        return null;

    const delisting = record[delistingType]!

    const delistedTitle = t('delisting.label_title', { reason: tags.nameOf(delisting.reason) ?? delisting.reason, date: delisting.date ? format(new Date(delisting.date),'PP',{locale}) : undefined})

    return <Label fill={delistColor} tip={delisting.note} icon={<DelistingIcon />} title={delistedTitle} {...rest} />

}