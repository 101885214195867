
import { BsLayerBackward, BsLayerForward } from 'react-icons/bs'

export const vesselType='vessel'
export const editModeParam='edit'
export const delistingParam='delisting'

export const HistoryIcon = BsLayerBackward
export const HistoryDeactivateIcon = BsLayerForward

