import { authorizationType } from '#authorization/constants'
import { Authorization } from '#authorization/model'
import { delistingType } from '#delisting/constants'
import { Delisting } from '#delisting/model'
import { detailsType } from '#details/constants'
import { Details } from '#details/model'
import { photographType } from '#photographs/constants'
import { Photographs } from '#photographs/model'
import { SubmissionRef } from '#submission/model'
import { trxauthzType } from '#trxauth/constants'
import { TrxAuthzs } from '#trxauth/model'
import { Vid } from '#vid/model'
import { Lifecycle } from 'apprise-frontend-core/utils/lifecycle'
import { TenantResource } from 'apprise-frontend-iam/tenant/model'


/** 
 * common to all records and record patches. 
 */
type AbstractRecord = TenantResource & {

    /** 
     * unique and internal, opaque from a domain perspective. 
     */
    id: RecordIdentifier,

    /** 
     *  dates the information at its source, not necessarily its ingestion.
     *  basis for ordering into history, identifies the current record. 
     */
    timestamp: string

    /**
     *  the primary vessel identifier.
     */
    uvi: Vid

    /**
     *  lifecycle metadata of the record/patch as it broadens its audience through the system.
     */
    lifecycle: Lifecycle<RecordState>

    /**
     *  the slots that changed to make this the current record.
     */
    patchedSlots: SlotType[]


    /**
     *  the original submission for this record, if any.
     */
    origin?: SubmissionRef

}


/**
 *  a "partial" record with some slots only, often just one.
 *  serves as a patch to update the current record.
 */
export type RecordPatch<T extends SlotType = never> = AbstractRecord & Pick<RecordSlots, T>


/**
 *  a record, with an unknown number of slots. may be full, may be a patch.
 *  used for generic routines that span full records and record patches.
 *  or wherever there's not need to preserve slot types.
 */
 export type GenericRecord = AbstractRecord & Partial<RecordSlots>
 
/**
 *  a public record  is a generic record with the only guarantee that it containes details. 
 *  it may container any other slots too, up to being "complete" with all possible slots.
 */
export type RavRecord = GenericRecord & Pick<RecordSlots, typeof detailsType | typeof authorizationType | typeof photographType> & {

    origin: SubmissionRef
}





export type RecordIdentifier = string


export type RecordState = 'uploaded' | 'ignored' | 'submitted' | 'pending' | 'rejected' | 'published'



type RecordSlots = {

    [detailsType]: Details
    [authorizationType]: Authorization
    [photographType]: Photographs
    [delistingType]: Delisting
    [trxauthzType]: TrxAuthzs

}


export const coreSlotTypes = [detailsType, authorizationType, photographType] as const
export const slotTypes = [...coreSlotTypes, delistingType, trxauthzType] as const

export type SlotType = typeof slotTypes[number]


export type RecordSlot = {

    /** 
     * internal, opaque from a domain perspective. 
     */
    id: string,

    /** 
     *  dates the information at its source, not necessarily its ingestion.
     *  basis for ordering into history, latest is in current record. 
     */
    timestamp: string
    
}