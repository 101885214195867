import { useT } from 'apprise-frontend-core/intl/language'
import { useTagCache } from 'apprise-frontend-tags/cache'
import parse from 'date-fns/parse'
import addMinutes from 'date-fns/addMinutes'
import { ParseIssue } from './model'


export const useParseUtils = () => {

    const t = useT()

    const codecache = useTagCache()

    const now = Date.now() //used as a reference date parsing in date-fns.

    const issueOf = <S, T = string>(parse: (_: T) => S, failTest: (_: S, __: T) => boolean, key: string = 'parse.processing_error') => ((value: T | undefined) => ({
        orIssue: (type: ParseIssue['type']) => ({
            about: (field: string) => ({
                on: (item: any) => {

                    if (!value && (value as any) !== 0)
                        return undefined

                    try {

                        const parsed = parse(value!)

                        if (!failTest(parsed, value!))
                            return parsed


                    } catch (error) {

                        console.warn("unexpected parse error", { value, field, error })

                    }


                    const error = new Error(t(key, { value, field, item }))

                    error['type'] = type

                    throw error
                }
            })
        })
    }))

    const self = {

        ...codecache
        ,

        int: issueOf(parseFloat, (v, s) => isNaN(v) || parseInt(s) !== v, "parse.int_error")

        ,

        float: (num:string| number | undefined, decimalDigits?: number) => issueOf( num => {

            const parsed = typeof num === 'number' ? num : parseFloat(num?.replace(',','.'))

            if (decimalDigits) {

                const pow = Math.pow(10, decimalDigits)
                
                return Math.round((parsed + Number.EPSILON) * pow) /pow
            
            }
            
      
            return parsed


        }, isNaN, "parse.number_error")(num === undefined || num === null ? num : `${num}`)

        ,


        date: (value: any, formats: string[] = []) => {

            let date = value

            // fallback: if we don't have a date object, try parsing as formatted text.
            //eslint-disable-next-line
            test: if (date && !(date instanceof Date)) {

                for (const f of formats) {

                    const dateFromFormat = parse(value, f, now)

                    const invalidDate = isNaN(dateFromFormat.getTime())

                    if (!invalidDate) {

                        // this is the difference between the local date interpreted in UTC and the date interpreted in local timezone (the browser's).
                        const offset = dateFromFormat.getTimezoneOffset()

                        // compensates the offset
                        date = offset===0 ? dateFromFormat : addMinutes(dateFromFormat,-offset)        
                        
                        //eslint-disable-next-line
                        break test
                    }
                    
                }

                // formats don't work

                const parsedAsJSDate = new Date(value) 

                 if (!isNaN(parsedAsJSDate.getTime())){

                    // this is the difference between the local date interpreted in UTC and the date interpreted in local timezone (the browser's).
                    const offset = parsedAsJSDate.getTimezoneOffset()

                    date = offset===0 ? parsedAsJSDate : addMinutes(parsedAsJSDate,-offset)    

                }

            }

            // if we have a date now, return as ISO.
            const parsed = date instanceof Date ? date.toISOString() : date

             // we haven't produced a date.
            const fail = parsed === value

            return issueOf(() => parsed, () => fail, "parse.date_error")(value)

        }

    }

    return self
}